import React, { useRef, useContext, useState, useEffect } from "react";
import tw from "twin.macro";
//import { Link } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-intl";
import { FirebaseAuthContext } from "../../context/FirebaseAuthContext";
import moment from "moment-timezone";
import Pagination from "./Pagination";
import Loading from "../common/LoadingElement";
import {
    RiDeleteBinLine,
    RiEyeLine,
    RiEyeOffLine,
    RiEditLine,
    //RiAnchorFill,
    RiRefreshLine,
} from "react-icons/ri";

import { BsInboxes } from "react-icons/bs";
import Img from 'react-cloudinary-lazy-image';

const Skills = ({ customLimit = 10 }) => {
    const intl = useIntl();
    const { updateIdToken } = useContext(FirebaseAuthContext);
    const elementRef = useRef();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        items: [],
        page: 1,
        pages: 1,
        limit: customLimit,
        total: 0,
    });

    // Refresca la lista
    const refreshData = async () => {
        setLoading(true);

        // Actualizamos idToken
        const token = await updateIdToken();
        token && fetch(
            `${process.env.GATSBY_API_URL}/v1/dashboard/skills/?page=${data.page}&limit=${data.limit}`,
            {
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
            },
        )
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then((data) => {
                setData({
                    items: data.skills,
                    page: data.pagination.page,
                    pages: data.pagination.pages,
                    limit: data.pagination.limit,
                    total: data.pagination.total,
                });
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const refreshDataCallBack = React.useCallback(refreshData, []);

    // Incrementamos o decrementamos páginas
    const prevPage = async () => {
        if (data.page > 1) {
            data.page--;
            await refreshData();
        }
    };

    // Incrementamos o decrementamos páginas
    const nextPage = async () => {
        // Si hay más páginas...
        if (data.page < data.pages) {
            data.page++;
            await refreshData();
        }
    };

    // Cargamos la lista nada más renderizar
    useEffect(() => {
        refreshDataCallBack();
    }, [refreshDataCallBack]);


    return (
        <>
            <div
                css={[
                    tw`flex flex-col font-montserrat m-5`,
                    loading ? [tw`opacity-25`] : [tw`opacity-100`],
                ]}
            >
                <div css={[tw`justify-between flex`]}>
                    <h3
                        ref={elementRef}
                        css={[tw`text-lg leading-6 font-medium font-montserrat text-gray-900`]}
                    >
                        {intl.formatMessage({ id: "48" })}
                    </h3>

                    <button
                        onClick={() => {
                            refreshData();
                        }}
                    >
                        <RiRefreshLine
                            css={[
                                tw`duration-300 h-6 w-6 text-gray-400`,
                                loading ? [tw`animate-spin cursor-not-allowed`] : [tw``],
                            ]}
                        />
                    </button>
                </div>
                <div css={[tw`-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-5`]}>
                    <div css={[tw`py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8`]}>
                        <div
                            css={[
                                tw`duration-500 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg`,
                                loading ? [tw`opacity-25`] : [tw`opacity-100`],
                            ]}
                        >
                            <table css={[tw`min-w-full divide-y divide-gray-200`]}>
                                <thead css={[tw`bg-gray-50`]}>
                                    <tr>
                                        <th
                                            scope="col"
                                            css={[
                                                tw`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`,
                                            ]}
                                        >
                                            {intl.formatMessage({ id: "34" })}
                                        </th>
                                        <th
                                            scope="col"
                                            css={[
                                                tw`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`,
                                            ]}
                                        >
                                            {intl.formatMessage({ id: "36" })}
                                        </th>
                                        <th
                                            scope="col"
                                            css={[
                                                tw`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`,
                                            ]}
                                        >
                                            {intl.formatMessage({ id: "56" })}
                                        </th>
                                        <th
                                            scope="col"
                                            css={[
                                                tw`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`,
                                            ]}
                                        >
                                            {intl.formatMessage({ id: "57" })}
                                        </th>
                                        <th
                                            scope="col"
                                            css={[
                                                tw`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`,
                                            ]}
                                        >{" "}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody css={[tw`bg-white divide-y divide-gray-200`]}>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="6" css={[tw`text-center px-6 py-3`]}>
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : data.items && data.items.length > 0 ? (
                                        data.items.map((item) => (
                                            <Skill key={item._id} item={item} />
                                        ))
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan="5"
                                                css={[
                                                    tw`text-center px-6 py-10 text-sm uppercase text-gray-400 font-medium`,
                                                ]}
                                            >
                                                <div>
                                                    <BsInboxes css={[tw`w-6 h-6 inline mr-3`]} />
                                                    {intl.formatMessage({ id: "1" })}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {data.elements}
                            <Pagination
                                page={data.page}
                                pages={data.pages}
                                limit={data.limit}
                                // items={data.items.length || 0}
                                total={data.total}
                                prevPage={prevPage}
                                nextPage={nextPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const Skill = ({ item }) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const { updateIdToken } = useContext(FirebaseAuthContext);
    const [data, setData] = useState(item);

    const editedBy = item.editedBy.slice(-1)[0] || null;

    // Cambiamos el estado de noticia
    const changeStateSkill = async () => {
        setLoading(true);
        const token = await updateIdToken();
        token && fetch(`${process.env.GATSBY_API_URL}/v1/dashboard/skills/${item._id}/change-status`, {
            method: "PATCH",
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    setData(data.skill);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {data && <tr
                css={
                    loading
                        ? [tw`cursor-not-allowed opacity-50 duration-100`]
                        : [tw`opacity-100 duration-100`]
                }
            >
                <td css={[tw`px-6 py-4 text-xs text-gray-500`]}>
                    <div css={[tw`flex items-center`]}>
                        <div css={[tw`h-10 w-10 rounded shadow mr-3 overflow-hidden`]}>
                            <Img
                                css={[tw`rounded`]}
                                cloudName={process.env.GATSBY_CLOUDINARY_NAME}
                                imageName={item.picture}
                                fluid={{ maxWidth: 320 }}
                                alt={item.name[intl.locale].match(/\b(\w)/g).join("")}
                            />
                        </div>

                        {data.name[intl.locale]}
                    </div>
                </td>
                <td css={[tw`px-6 py-4 text-xs text-gray-500`]}>
                    <button
                        css={[
                            tw`px-2 inline-flex text-xs leading-5 font-semibold rounded-md py-1 duration-100`,
                            data.enabled
                                ? [tw`bg-green-100 text-green-800`]
                                : [tw`bg-red-100 text-red-800`],
                        ]}
                        onClick={async () => {
                            await changeStateSkill();
                        }}
                    >
                        {data.enabled ? (
                            <RiEyeLine
                                css={[tw`h-5 w-5 text-green-800`]}
                                title={intl.formatMessage({ id: "46" })}
                            />
                        ) : (
                            <RiEyeOffLine
                                css={[tw`h-5 w-5 text-red-800`]}
                                title={intl.formatMessage({ id: "47" })}
                            />
                        )}
                    </button>
                </td>
                <td css={[tw`px-6 py-4 text-xs text-gray-500`]}>{moment(data.updated_at).tz("Europe/Madrid").format("DD/MM/YYYY HH:mm")}</td>
                <td css={[tw`px-6 py-4 text-xs text-gray-500`]}>
                    {editedBy && editedBy.user &&
                        <>
                            <img
                                loading="lazy"
                                css={[tw`h-8 w-8 rounded-full shadow inline mr-3`]}
                                src={editedBy.user.picture || editedBy.user.avatar}
                                alt={data.name[intl.locale].match(/\b(\w)/g).join("")}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = require("../../images/misc/unknown.png");
                                }}
                            />
                            {editedBy.user.name}
                        </>
                    }
                </td>
                <td css={[tw`px-6 py-4 text-xs text-gray-500`]}>
                    <Link to={`/skills/edit/${data._id}/`}>
                        <RiEditLine
                            css={[
                                tw`h-4 w-4 text-gray-500 inline mr-3 duration-300 hover:text-gray-800`,
                            ]}
                        />
                    </Link>

                    <button
                        disabled={true}
                        css={[tw`opacity-25 cursor-not-allowed`]}
                        onClick={async () => { }}
                    >
                        <RiDeleteBinLine
                            css={[
                                tw`h-4 w-4 text-gray-500 inline duration-300 hover:text-gray-800`,
                            ]}
                        />
                    </button>
                </td>
            </tr>
            }
        </>
    );
};

export default Skills;